"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
const generator_1 = require("./lib/generator");
const ngRightClick_1 = require("@src/app/directives/ngRightClick");
const defaultSize = 7;
const sizeInitial = function (value) {
    let numValue = parseInt(value || defaultSize) || defaultSize;
    if ([5, 6, 7, 8, 9, 10, 11, 12].indexOf(numValue) < 0) {
        numValue = defaultSize;
    }
    return numValue;
};
class HitoriCtrl {
    constructor($scope, $timeout, $location, $q, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$q = $q;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.blacks = 0;
        this.size = sizeInitial(this.$location.search()['size']);
        this.changed = false;
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        this.gameFinish = false;
        this.$scope.$watch('$ctrl.debugInput', (debugInput) => {
            if (debugInput) {
                const numbers = debugInput.split(' ').filter((item) => {
                    return item.replaceAll(' ', '') != '';
                }).map((item) => {
                    return parseInt(item.replaceAll(' ', ''));
                });
                if (numbers.length == Math.pow(this.size, 2)) {
                    this.grid = numbers.map((item) => {
                        return { num: item };
                    });
                }
            }
        });
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            const e = new MouseEvent('click', { bubbles: true, cancelable: false });
            if (!localStorage.getItem(`${this.constructor.name}_game`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        localStorage.removeItem(`${this.constructor.name}_game`);
                        localStorage.removeItem(`${this.constructor.name}_startWith`);
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    $onInit() {
        const savedData = JSON.parse(localStorage.getItem(`${this.constructor.name}_game`) || 'null');
        if (!savedData) {
            this.newGame();
        }
        else {
            this.changed = true;
            this.grid = savedData.grid;
            this.size = savedData.size;
        }
        this.setParam('size', this.size);
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(0);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return rxjs_1.EMPTY;
        })).subscribe();
    }
    setSize(size) {
        this.newGame(size);
    }
    setParam(name, value) {
        if (name == 'size') {
            if (value == defaultSize) {
                this.$location.search('size', null);
            }
            else {
                this.$location.search('size', value);
            }
        }
    }
    newGame(size = undefined) {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                if (size)
                    this.size = size;
                this.setParam('size', this.size);
                this.makePuzzle();
            }
        });
    }
    reset() {
        this._confirmNewGame((result) => {
            if (result) {
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.changed = false;
                this.grid.forEach((item) => {
                    delete item.mark;
                    item.status &= 32;
                    item.error = undefined;
                });
            }
        });
    }
    makePuzzle() {
        this._startTimer.next(null);
        this.startWith = 0;
        this.timeRemaining = '00:00';
        this.gameFinish = false;
        const game = (0, generator_1.hitori_generate_board)({}, this.size, 0);
        this.grid = [].concat.apply([], game.board);
    }
    floor(v) {
        return Math.floor(v);
    }
    debug() {
        console.log(ng.copy(this.grid));
        this.grid.forEach((item) => {
            item.mark = undefined;
            item.error = undefined;
            item.status &= ~32;
            if (item.status == 4) {
                item.mark = 'black';
            }
        });
    }
    puzzleLeftClick($event, index) {
        if (!this.gameFinish) {
            this.changed = true;
            if (this.ConfigService.cookieSettings.left_mousedown_cycle) {
                if (this.grid[index].mark == 'black') {
                    this.grid[index].mark = 'green';
                    this.SoundService.play('green');
                }
                else if (this.grid[index].mark == 'green') {
                    this.grid[index].mark = undefined;
                }
                else if (!this.grid[index].mark) {
                    this.grid[index].mark = 'black';
                    this.SoundService.play('black');
                }
            }
            else {
                if (this.grid[index].mark != 'black') {
                    this.grid[index].mark = 'black';
                    this.SoundService.play('black');
                }
                else {
                    this.grid[index].mark = null;
                }
            }
            const win = this.checkWin();
            this.save();
            if (win) {
                this.endGame();
            }
        }
    }
    puzzleRightClick($event, index) {
        if (!this.gameFinish) {
            this.changed = true;
            if (this.grid[index].mark != 'green') {
                this.grid[index].mark = 'green';
                this.SoundService.play('green');
            }
            else {
                this.grid[index].mark = null;
            }
            const win = this.checkWin();
            this.save();
            if (win) {
                this.endGame();
            }
        }
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this._resume.next('visible');
            });
        }
    }
    checkWin() {
        const board = [];
        let row = [];
        const minBlackCount = this.grid.filter((item) => item.status == 4).length;
        let blackCount = 0;
        ng.copy(this.grid).forEach((item, i) => {
            item.status = undefined;
            if (item.mark == 'black') {
                item.status = 2;
                blackCount += 1;
            }
            if (i == 0) {
                row.push(item);
            }
            else {
                if ((i % this.size) == 0) {
                    board.push([...row]);
                    row = [item];
                }
                else {
                    row.push(item);
                }
            }
        });
        board.push(row);
        // console.log({
        //     blackCount: blackCount,
        //     hitori_check_rule2: hitori_check_rule2({board, board_size: this.size}),
        //     hitori_check_rule3: hitori_check_rule3({board, board_size: this.size}),
        //     hitori_check_rule4: hitori_check_rule4({board, board_size: this.size}),
        // })
        this.grid.forEach((item) => {
            item.status &= ~32;
            item.error = undefined;
        });
        const alongside = (0, generator_1.hitori_check_rule2)({ board, board_size: this.size });
        if (!alongside) {
            board.forEach((row, x) => {
                row.forEach((item, y) => {
                    if (item.status >= 32) {
                        this.grid[x * this.size + y].status = item.status;
                    }
                });
            });
        }
        const area = (0, generator_1.hitori_check_rule3)({ board: ng.copy(board), board_size: this.size });
        if (!area) {
            this.grid.forEach((item) => {
                if (item.status < 32) {
                    item.error = 'area';
                }
            });
        }
        if ((minBlackCount <= blackCount) &&
            // рядом
            alongside &&
            // область
            area &&
            // дубли
            (0, generator_1.hitori_check_rule4)({ board, board_size: this.size })) {
            return true;
        }
        return false;
    }
    save() {
        this._startTimer.next(true);
        localStorage.setItem(`${this.constructor.name}_game`, ng.toJson({
            size: this.size,
            grid: this.grid,
        }));
    }
    endGame() {
        this._stopTimer.next(null);
        this.gameFinish = true;
        this.SoundService.play('win');
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                timeRemaining: this.timeRemaining,
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            localStorage.removeItem(`${this.constructor.name}_game`);
            localStorage.removeItem(`${this.constructor.name}_startWith`);
            if (result == 'newGame') {
                this.newGame();
            }
        });
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return rxjs_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem(`${this.constructor.name}_startWith`, sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !localStorage.getItem(`${this.constructor.name}_game`)) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            const timerWork = this.timerWork;
            this._stopTimer.next(null);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    if (timerWork) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
}
HitoriCtrl.$inject = ['$scope', '$timeout', '$location', '$q', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.directive('ngRightClick', ngRightClick_1.NgRightClick);
appModule.component('gameHitori', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: HitoriCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('hitori/');
        SoundServiceProvider.setSound({
            'win': require('./sounds/win.mp3').default,
            'green': require('./sounds/flag.mp3').default,
            'black': require('./sounds/hint.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            highlight_errors: true,
            show_timer: true,
            sound_effects: false,
            left_mousedown_cycle: true,
        });
    }]);
