"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NgRightClick = void 0;
const ng = window.angular;
function NgRightClick($parse) {
    return {
        restrict: 'A',
        //@ts-ignore
        link: (scope, element, attrs) => {
            var fn = $parse(attrs.ngRightClick);
            element.bind('contextmenu', function (event) {
                scope.$apply(function () {
                    event.preventDefault();
                    fn(scope, { $event: event });
                });
            });
        }
    };
}
exports.NgRightClick = NgRightClick;
NgRightClick.$inject = ['$parse'];
